import { Component } from '@angular/core';

@Component({
  selector: 'svnl-register-dialog',
  templateUrl: './year-error-dialog.component.html',
  styleUrls: ['./year-error-dialog.component.scss'],
})
export class YearErrorDialogComponent {

  constructor() {
  }
}
